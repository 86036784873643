import { graphql } from 'gatsby'
import get from 'lodash/get'
import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import BlogPostListing from '../components/BlogPostListing'

class BlogPostIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const siteDescription = get(
      this,
      'props.data.site.siteMetadata.description'
    )
    const posts = get(this, 'props.data.allMarkdownRemark.edges').filter(
      p => p.node.frontmatter.template === 'blog'
    )

    return (
      <Layout location={this.props.location}>
        <Helmet
          htmlAttributes={{ lang: 'en' }}
          meta={[{ name: 'description', content: siteDescription }]}
          title={siteTitle}
        />
        <h1>Blog</h1>
        {posts.map(({ node }) => (
          <BlogPostListing node={node} key={node.id} />
        ))}
      </Layout>
    )
  }
}

export default BlogPostIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          id
          html
          excerpt(format: HTML)
          fields {
            tagSlugs
            slug
            readingTime {
              text
            }
          }
          frontmatter {
            template
            title
            date(formatString: "MMMM DD, YYYY")
            tags
            author
          }
        }
      }
    }
  }
`
