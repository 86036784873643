import { Link } from 'gatsby'
import get from 'lodash/get'
import React from 'react'
import { rhythm } from '../utils/typography'
import typeset from '../utils/typeset'

class BlogPostListing extends React.Component {
  render() {
    const { node } = this.props
    const title = get(node, 'frontmatter.title') || node.fields.slug
    const tags = node.fields.tagSlugs
      ? node.fields.tagSlugs.map((tag, i) => {
          const divider = i < node.fields.tagSlugs.length - 1 && (
            <span>{', '}</span>
          )
          return (
            <span key={tag}>
              <Link to={tag}>{node.frontmatter.tags[i]}</Link>
              {divider}
            </span>
          )
        })
      : null
    return (
      <div key={node.fields.slug} style={{ marginBottom: rhythm(1) }}>
        <h2 style={{ marginTop: 0, marginBottom: rhythm(-1 / 8) }}>
          <Link to={'/blog' + node.fields.slug}>{title}</Link>
        </h2>
        <small>
          {node.frontmatter.date} · by {node.frontmatter.author} ·{' '}
          {node.fields.readingTime.text}
          {tags && ` · `}
          {tags}
        </small>
        <p dangerouslySetInnerHTML={{ __html: typeset(node.excerpt) }} />
      </div>
    )
  }
}

export default BlogPostListing
